html, body, #root, #material-wrap, #material-wrap-children {
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.App {
  height: 100%;
}

.App-hr.vertical {
  height:100%; /* you might need some positioning for this to work, see other questions about 100% height */
  width:0;
  border:1px solid black;
}
