body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html, body, #root, #material-wrap, #material-wrap-children {
  height: 100%;
  overflow: hidden;
  margin: 0;
}
.App {
  height: 100%;
}

.App-hr.vertical {
  height:100%; /* you might need some positioning for this to work, see other questions about 100% height */
  width:0;
  border:1px solid black;
}

.WithFilter-container {
    height: 100%;
    width: 100%;
}
.WithFilter-body {
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    width: auto;
}

.WithFilter-filter {
    float: right;
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    width: 320px;
    border-left: 1px solid #A5A2A2;
}
