.WithFilter-container {
    height: 100%;
    width: 100%;
}
.WithFilter-body {
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    width: auto;
}

.WithFilter-filter {
    float: right;
    overflow: auto;
    box-sizing: border-box;
    height: 100%;
    width: 320px;
    border-left: 1px solid #A5A2A2;
}